import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import CotizarButton from './cotizar-button';

export default function WhatWeDo() {
    return (
        <>
            <Container id="lo-que-hacemos" className="what-we-do" disableGutters={true} maxWidth={false}>
                <span className="slide"></span>
                <Typography variant="h2" marginTop="2em">
                    Esto es lo que <span className="medium">Hacemos</span>
                </Typography>
                <Typography variant="p" component="p" className="mediumText" marginTop="1em" marginBottom="3em">
                    Nuestra fórmula para el éxito es trabajar enfocados en tu audiencia.<br />
                    <span className="medium">A partir de allí combinamos estrategia + creatividad + buen diseño</span><br />
                    para crear la solución. Estos son los servicios que ofrecemos:
                </Typography>
                <ul className="services-container">
                    <li>GESTIÓN DE<br /><span className="bold">REDES SOCIALES</span></li>
                    <li>DESARROLLO<br /><span className="bold">DE SITIOS WEB</span></li>
                    <li>ESTRATEGIA<br /><span className="bold">DIGITAL</span></li>
                    <li>COMMUNITY<br /><span className="bold">MANAGEMENT</span></li>
                    <li>PUBLICIDAD<br /><span className="bold">ONLINE Y SEO</span></li>
                    <li>ASESORAMIENTO<br /><span className="bold">ESTRATÉGICO</span></li>
                    <li>CONTENIDO<br /><span className="bold">MULTIMEDIA</span></li>
                    <li>ESTRATEGIAS<br /><span className="bold">DE MARKETING</span></li>
                    <li><span className="bold">BRANDING</span><br />
                        <span className="regularText">Una marca es mucho<br />
                        más que su imagen</span></li>
                </ul>
                <CotizarButton></CotizarButton>
            </Container>
        </>
    );
}