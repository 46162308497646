import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import CotizarButton from './cotizar-button';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function Portada() {
    const matches = useMediaQuery('(min-width:800px)');
    return (
        <>
            <Container className={matches ? "portada" : "portada mobile"} disableGutters={true} maxWidth={false}>
                <Typography variant="h1" className={!matches && 'mobile'}>
                    CONECTA CON<br />
                    TUS POTENCIALES<br />
                    CLIENTES
                </Typography>
                <span className="slide"></span>
                <Typography variant="h2" marginBottom="2em">
                    Bienvenido a la era de la<br />
                    <span className="medium">transformación digital</span>
                </Typography>
                <CotizarButton></CotizarButton>
            </Container>
        </>
    );
}