import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import CotizarButton from './cotizar-button';
// IMAGES
import donalejandroImg from './images/donalejandro_site.png';
import junowebImg from './images/junoweb_site.png';
import lahuellaImg from './images/lahuella_site.png';
import donalejandroFeedImg from './images/donalejandro_feed.png';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
const styles = {
    root: {
        position: 'relative',
    },
    slide: {
        padding: 15,
        minHeight: 150,
        color: '#fff',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        padding: '4em 1em'
    },
    slideFeed: {
        padding: 15,
        minHeight: 150,
        color: '#000',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '4em 1em'
    },
    image: {
        width: '100%',
        maxWidth: '720px',
        minWidth: '240px'
    },
    imageFeed: {
        width: '100%',
        maxWidth: '282px'
    }
};

class CarouselClients extends React.Component {
    state = {
        index: 0,
    };
    handleChangeIndex = index => {
        this.setState({
            index,
        });
    };

    render() {
        const { index } = this.state;
        // const matches = useMediaQuery('(min-width:800px)');
        return (
            <div style={styles.root}>
                <AutoPlaySwipeableViews index={index} onChangeIndex={this.handleChangeIndex} interval={5000}>
                    <div style={Object.assign({}, styles.slide)}>
                        <img src={donalejandroImg} style={styles.image} />
                        <Typography variant="p" component="p" className="lightText" textAlign="left" marginLeft="1em" marginTop="2em">
                            <span className="medium">Creatividad – Website</span>
                            <br />E - Commerce / tienda Digital:
                            <br />Aceites Don Alejandro
                        </Typography>
                    </div>
                    <div style={Object.assign({}, styles.slide)}>
                        <img src={junowebImg} style={styles.image} />
                        <Typography variant="p" component="p" className="lightText" textAlign="left" marginTop="2em">
                            <span className="medium">Creatividad – Website</span>
                            <br />Landing Page USA/ Chatanooga:
                            <br />Juno Photography
                        </Typography>
                    </div>
                    <div style={Object.assign({}, styles.slide)}>
                        <img src={lahuellaImg} style={styles.image} />
                        <Typography variant="p" component="p" className="lightText" textAlign="left" marginTop="2em">
                            <span className="medium">Creatividad – Website</span>
                            <br />E - Commerce / tienda Digital:
                            <br />La Huella Uruguay
                        </Typography>
                    </div>
                </AutoPlaySwipeableViews>
            </div>
        );
    }
}

export default function TrabajosRecientes() {
    return (
        <>
            <Container className="trabajos-recientes" disableGutters={true} maxWidth={false}>
                <Typography variant="h2">
                    Trabajos
                    <span className="medium"> Recientes</span>
                </Typography>
                <span className="slide"></span>
                <Typography variant="p" component="p" className="lightText">
                    En Server transformamos realidades mediante estrategias creativas.
                    <br />Queremos que nuestros proyectos te hablen.
                </Typography>
                <CarouselClients></CarouselClients>
            </Container>
            <Container className="trabajos-recientes-feed" disableGutters={true} maxWidth={false}>
                <Typography variant="h2">
                    Cuando el
                    <span className="medium"> Feed lo dice todo</span>
                </Typography>
                <span className="slide"></span>
                <div style={Object.assign({}, styles.slideFeed)}>
                    <img src={donalejandroFeedImg} style={styles.imageFeed} />
                    <Typography variant="p" component="p" textAlign="left" marginLeft="2em" marginTop="2em">
                        <span className="medium">Don Alejandro
                            <br />Creatividad - Social Media
                        </span>
                        <br />Gestión de Redes Sociales
                        <br />Diseño, Marketing, Estrategia
                    </Typography>
                </div>
                <CotizarButton></CotizarButton>
            </Container>
        </>
    );
}