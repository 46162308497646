import * as React from 'react';
import Box from '@mui/material/Box';
import { HashLink } from 'react-router-hash-link';


export default function NavTabs() {
  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -156;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  }
  return (
    <Box className="App-menu" sx={{ width: '100%' }}>
      <HashLink smooth to={'/#sobre-nosotros'}
        // activeClassName="selected"
        scroll={el => scrollWithOffset(el)}
        className="menu-link">
        CONOCENOS
      </HashLink>
      <HashLink smooth to={'/#lo-que-hacemos'}
        // activeClassName="selected"
        scroll={el => scrollWithOffset(el)}
        className="menu-link">
        SERVICIOS
      </HashLink>
      <HashLink smooth to={'/#cotizar'}
        // activeClassName="selected"
        scroll={el => scrollWithOffset(el)}
        className="menu-link">
        COTIZAR
      </HashLink>
      {/* <LinkTab label="CONOCENOS" href="/about-us" /> */}
      {/* <LinkTab label="SERVICIOS" href="/servicios" />
        <LinkTab label="COTIZAR" href="/cotizar" /> */}
      {/* </Tabs> */}
    </Box>
  );
}