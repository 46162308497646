import logo from './logo.png';
import './App.css';
import NavTabs from './menu';
import MenuMobile from './menu-mobile';
import Portada from './portada';
import AboutUs from './about-us';
import TrabajosRecientes from './trabajos-recientes';
import WhatWeDo from './what-we-do';
import Transformamos from './transformamos';
import Container from '@mui/material/Container';
import ContactUs from './contact-us';
import useMediaQuery from '@mui/material/useMediaQuery';
import { HashLink } from 'react-router-hash-link';

function App() {
  const matches = useMediaQuery('(min-width:800px)');
  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -156;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  }
  return (
    <div className={ matches ? "App" : "App mobile"}>
      {!matches && <MenuMobile></MenuMobile>}
      <header className={!matches ? "App-header mobile" : "App-header"} >
        {matches ? (<HashLink smooth to={'/#'}
          scroll={el => scrollWithOffset(el)}>
          <img src={logo} className="App-logo" alt="logo" />
        </HashLink>) : <img src={logo} className="App-logo" alt="logo" />}
        {matches && <NavTabs></NavTabs>}
      </header>
      <Container disableGutters={true} maxWidth={false}>
        <Portada></Portada>
        {/* <AboutUs></AboutUs> */}
        <TrabajosRecientes></TrabajosRecientes>
        <WhatWeDo></WhatWeDo>
        <Transformamos></Transformamos>
        <ContactUs></ContactUs>
      </Container>
    </div>
  );
}

export default App;
