import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function Transformamos() {
    const matches = useMediaQuery('(min-width:800px)');
    return (
        <>
            <Container className="transformamos" disableGutters={true} maxWidth={false}>
                <Typography variant="h1" component="h2" className={matches ? "bigText" : "bigText mobile"}>
                    TRANSFORMAMOS TUS<br />
                    IDEAS EN RESULTADOS
                </Typography>
                <span className="slide"></span>
                <Typography variant="h2">
                    TRABAJEMOS JUNTOS, PARA<br />
                    <span className="bold">TENER GRANDES LOGROS</span>
                </Typography>
                {/* <Button variant="contained" size="large" className={ matches ? "cotizar" : "cotizar mobile"}>
                    HABLEMOS UN POCO MÁS
                </Button> */}
            </Container>
        </>
    );
}