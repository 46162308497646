import * as React from 'react';
import { useState } from "react";
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { alpha, styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';
import FormControl from '@mui/material/FormControl';
import useMediaQuery from '@mui/material/useMediaQuery';
import axios from "axios";
import Config from "./config.json"

// IMAGES
import logo from './images/logo_white.png';
import whatsapp from './images/whatsapp_icon.png';
import instagram from './images/instagram_icon.png';

const environment = Config.ENV;
const baseUrl = Config.BASE_URL;


const styles = {
    label: {
        color: '#fff',
        fontSize: '1.1rem'
    }
}
const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
        position: 'relative',
        backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
        border: '1px solid #fff',
        fontSize: 18,
        width: '100%',
        padding: '10px 12px',
        transition: theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
        ]),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            boxShadow: `${alpha('#fff', 0.25)} 0 0 0 0.2rem`,
            borderColor: '#fff',
        },
    },
}));
const initialFormValues = {
    fullName: "",
    email: "",
    number: "",
    service: ""
}

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function TransitionUp(props) {
    return <Slide {...props} direction="up" />;
}

export default function ContactUs() {
    const matches = useMediaQuery('(min-width:800px)');

    const [values, setValues] = useState(initialFormValues);

    const [state, setState] = React.useState({
        open: false,
        vertical: 'bottom',
        horizontal: 'center',
        severity: 'success'
    });

    const { vertical, horizontal, severity, open } = state;

    const successSnackBar = () => {
        setState({ ...state, open: true, severity: "success" });
    };

    const errorSnackBar = () => {
        setState({ ...state, open: true, severity: "error"});
    };

    const handleCloseSnackBar = () => {
        setState({ ...state, open: false });
    };


    const handleInputValue = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value
        });
    }

    const postContactForm = async ({ fullName, email, number, service }) => {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Clear-Site-Data": "*"
            }
        };
        const data = JSON.stringify({ fullName, email, number, service });
        try {

            await axios.post("http://localhost:4000/send_mail", {
                data, config
            })
            setValues(initialFormValues);
            successSnackBar();
        } catch (error) {
            errorSnackBar();
            console.log(error)
        }
    };

    const handleFormSubmit = (e) => {
        // this function will be triggered by the submit event
        e.preventDefault();
        postContactForm(values);
    }

    return (
        <>
            <Container id="cotizar" className={matches ? "contact-us desktop" : "contact-us"} disableGutters={true} maxWidth={false}>
                <div className="item">
                    <img src={logo} className={matches ? "logo-white" : "logo-white mobile"} alt="logo" />
                </div>
                <div className="item">
                    <Typography variant="h3" marginBottom="1em" marginLeft={matches ? '2.5%' : '5%'} textAlign="left">¡ESCRÍBENOS!</Typography>
                    <form onSubmit={handleFormSubmit}>
                        <Grid container>
                            <Grid xs={12} sm={6} item marginBottom="0.5em">
                                <FormControl variant="standard" className="formField">
                                    <InputLabel shrink htmlFor="bootstrap-input" style={Object.assign({}, styles.label)}>
                                        NOMBRE / APELLIDO
                                    </InputLabel>
                                    <BootstrapInput required value={values.fullName} id="bootstrap-input" name="fullName" onChange={handleInputValue} />
                                </FormControl>
                            </Grid>
                            <Grid xs={12} sm={6} item marginBottom="0.5em">
                                <FormControl variant="standard" className="formField">
                                    <InputLabel shrink htmlFor="bootstrap-input" style={Object.assign({}, styles.label)}>
                                        CORREO
                                    </InputLabel>
                                    <BootstrapInput required value={values.email} id="bootstrap-input" type="email" name="email" onChange={handleInputValue} />
                                </FormControl>
                            </Grid>
                            <Grid xs={12} sm={6} item marginBottom="0.5em">
                                <FormControl variant="standard" className="formField">
                                    <InputLabel shrink htmlFor="bootstrap-input" style={Object.assign({}, styles.label)}>
                                        TELEFONO
                                    </InputLabel>
                                    <BootstrapInput required value={values.number} id="bootstrap-input" type="number" name="number" onChange={handleInputValue} />
                                </FormControl>
                            </Grid>
                            <Grid xs={12} sm={6} item marginBottom="0.5em">
                                <FormControl variant="standard" className="formField">
                                    <InputLabel shrink htmlFor="bootstrap-input" style={Object.assign({}, styles.label)}>
                                        SERVICIO
                                    </InputLabel>
                                    <BootstrapInput required value={values.service} id="bootstrap-input" name="service" onChange={handleInputValue} />
                                </FormControl>
                            </Grid>
                            <Grid xs={12} sm={6} item className="submitBtn">
                                <Button variant="contained" size="large" type="submit" className="enviar-btn">
                                    ENVIAR
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </div>
                <div className="item">
                    <Typography variant="p" component="p" className="lightText" marginBottom="1.75em" textAlign={matches ? "left" : "center"}>
                        COTIZACIONES:<br />
                        HOLA@SERVERAGENCIADIGITAL.COM
                    </Typography>
                    <Typography variant="p" component="p" className="lightText" marginBottom="1.75em" textAlign={matches ? "left" : "center"}>
                        TELEFONO:<br />
                        +598 96 219 598
                    </Typography>
                    <Typography variant="p" component="p" className="lightText" marginBottom="1.75em" textAlign={matches ? "left" : "center"}>
                        HORARIOS:<br />
                        9:00 A.M - 2:00 P.M.<br />
                        3:00 P.M - 6:00 P.M.
                    </Typography>
                </div>
                <div className="item icons-container">
                    <a className="icons" href="https://www.instagram.com/agenciaserver/" target="_blank"><img src={instagram} alt="instagram" /></a>
                    <a className="icons" href="http://web.whatsapp.com/send?phone=+59896219598&abid=+59896219598" target="_blank"><img src={whatsapp} alt="whatsapp" /></a>
                </div>
                <Snackbar TransitionComponent={TransitionUp} anchorOrigin={{ vertical, horizontal }} open={open} autoHideDuration={6000} onClose={handleCloseSnackBar}>
                    <Alert onClose={handleCloseSnackBar} severity={severity === "success" ? "success" : "error"} sx={{ width: '100%' }}>
                        {severity === "success" ? "¡Tu mensaje se ha enviado! En breve te responderemos." : "Lo sentimos, el mensaje no se pudo enviar. ¡Intenta de nuevo!"}
                    </Alert>
                </Snackbar>
            </Container>
        </>
    );
}