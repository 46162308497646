import * as React from 'react';
import Button from '@mui/material/Button';
import { HashLink } from 'react-router-hash-link';

export default function CotizarButton() {
    return (
        <>
            <Button variant="contained" size="large" className="cotizar">
                <HashLink  smooth to={'/#cotizar'}>
                    COTIZAR
                </HashLink>
            </Button>
        </>
    );
}