import * as React from 'react';
import { slide as Menu } from 'react-burger-menu';
import { HashLink } from 'react-router-hash-link';

var styles = {
  bmBurgerButton: {
    position: 'fixed',
    width: '36px',
    height: '30px',
    right: '36px',
    top: '36px'
  },
  bmBurgerBars: {
    background: '#373a47'
  },
  bmBurgerBarsHover: {
    background: '#a90000'
  },
  bmCrossButton: {
    height: '24px',
    width: '24px'
  },
  bmCross: {
    background: '#bdc3c7'
  },
  bmMenu: {
    background: '#303030',
    padding: '2.5em 0 0',
    fontSize: '1.15em',
    overflowY: 'hidden'
  },
  bmItemList: {
    color: '#b8b7ad',
    padding: '0'
  },
  bmItem: {
    display: 'block',
    color: '#fff',
    fontFamily: 'TommySoft-Medium',
    padding: '1em',
    textDecoration: 'none'
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.7)'
  }
}

class MenuMobile extends React.Component {

  render () {
    // NOTE: You also need to provide styles, see https://github.com/negomi/react-burger-menu#styling
    return (
      <Menu disableCloseOnEsc width={ '40%' } right styles={ styles } >
        <HashLink smooth to={'/#'}
        className="menu-item">
        INICIO
      </HashLink>
        <HashLink smooth to={'/#sobre-nosotros'}
        className="menu-item">
        CONOCENOS
      </HashLink>
      <HashLink smooth to={'/#lo-que-hacemos'}
        className="menu-item">
        SERVICIOS
      </HashLink>
      <HashLink smooth to={'/#cotizar'}
        className="menu-item">
        COTIZAR
      </HashLink>
      </Menu>
    );
  }
}

export default MenuMobile;
